import React from 'react';
import styled from 'styled-components'

const IntroTitle1 = styled.p`
  font-family:times-new-roman;
  font-size:3vh;
  color:black;
  font-weight:600;
  margin:0;
  padding:0;
  margin-top:1vh;
  margin-bottom:1vh;
  margin-right:10px;
`

export default IntroTitle1;